.p-greetings {
  &_main {
    margin-bottom: 6rem;

    @include sp {
      margin-bottom: 3rem;
    }

    &Txt {

    }

    &Img {
      flex-shrink: 0;
      width: 40%;

      @include sp {
        width: 100%;
      }
    }
  }
}