.p-message {
  &_txt {
    font-size: 16px;
    margin: 0 auto 4.8rem;
    width: 80%;
    text-align: center;
    line-height: 1.8;

    @include sp {
      width: 100%;
      margin-bottom: 2.4rem;
      text-align: left;
    }
  }
}