.c-row {
  display: flex;
  align-items: center;
  margin: 0 -2rem;
  
  @include sp {
    margin: 0;
    flex-direction: column;
  }

  &_item {
    flex-grow: 1;
    padding: 0 2rem;

    @include sp {
      padding: 0;
      margin: 0 0 2rem;
      width: 100%;
    }
  }
}