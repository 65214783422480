.c-title {
  margin: 0 auto 4rem;

  @include sp {
    margin-bottom: 2rem;
  }

  &_sub {
    margin: 0 auto 2rem;
    text-align: center;
    font-weight: bold;
    color: $main;

    @include sp {
      margin-bottom: 1rem;
    }
  }

  &_main {
    margin: auto;

    &--left {
      margin: 0 auto 0 0;
    }
  }

  &--far {
    @extend .c-title;
    margin: 0 auto 6rem;
  }
}
