@charset "UTF-8";


/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

li {
  list-style: none;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select,
img {
  vertical-align: bottom;
}

@keyframes scrolldown {
  0% {
    background-position: 0 100%;
  }
  40% {
    background-position: 0 0%;
  }
  75% {
    background-position: 0 0%;
  }
  90% {
    background-position: 0 -100%;
  }
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  body {
    line-height: 1.8;
  }
}

body.is-overflow-hidden {
  overflow: hidden;
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: "Noto Sans JP", sans-serif, "メイリオ", Meiryo;
  }
}

main {
  display: block;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

img {
  max-width: 100%;
  display: block;
  margin: auto;
}

a {
  text-decoration: none;
  color: inherit;
  display: block;
  transition: opacity 0.3s ease;
}

@media screen and (min-width: 768px) {
  a:hover {
    opacity: 0.85;
  }
}

@media screen and (min-width: 768px) {
  .pc {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sp {
    display: block;
  }
}

.l-main {
  position: relative;
}

.l-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 2rem 0 0;
  color: #fff;
  z-index: 99;
}

@media screen and (max-width: 900px) {
  .l-header {
    padding: 0;
  }
}

@media screen and (max-width: 900px) {
  .l-header_inner--pc {
    display: none;
  }
}

.l-header_inner--mobile {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity .3s .1s;
}

@media screen and (min-width: 901px) {
  .l-header_inner--mobile {
    display: none;
  }
}

.l-header_inner--mobile.is-active {
  overflow: auto;
  opacity: 1;
  height: 100vh;
}

.l-header_menu {
  margin: 0 20% 0 auto;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 900px) {
  .l-header_menu {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0;
    background-color: #ED6B00;
    height: 100%;
  }
}

.l-header_item {
  text-align: center;
  width: 25%;
  max-width: 12.5rem;
  border-right: 1px solid #fff;
}

@media screen and (max-width: 900px) {
  .l-header_item {
    width: 100%;
    max-width: none;
    border-right: none;
    transform: translateY(-40%);
  }
}

.l-header_item:last-child {
  border: none;
}

@media screen and (max-width: 900px) {
  .l-header_link {
    width: 50%;
    line-height: 2;
    margin: 2rem auto;
  }
}

.l-header_contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8%;
  top: 0;
  background-color: #ED6B00;
  width: 126px;
  height: 65px;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 75%, 85% 100%, 15% 100%, 0 75%);
}

.l-header_contactArrow {
  margin-top: 0.5rem;
}

.l-header_menuTrigger {
  background-color: #ED6B00;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 8rem;
  height: 8rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  z-index: 99;
  border: none;
  outline: none;
}

@media screen and (max-width: 900px) {
  .l-header_menuTrigger {
    display: flex;
  }
}

.l-header_menuTrigger_line, .l-header_menuTrigger_line01, .l-header_menuTrigger_line02, .l-header_menuTrigger_line03 {
  width: 3rem;
  height: 1px;
  background-color: #fff;
  transform-origin: right center;
  transition: all .3s ease;
}

.l-header_menuTrigger_line02 {
  margin: .8rem 0;
}

.l-header_menuTrigger.is-active .l-header_menuTrigger_line01 {
  transform: rotate(-45deg) translateY(-0.3rem);
}

.l-header_menuTrigger.is-active .l-header_menuTrigger_line02 {
  width: 0;
  opacity: 0;
}

.l-header_menuTrigger.is-active .l-header_menuTrigger_line03 {
  transform: rotate(45deg) translateY(0.3rem);
}

.l-footer {
  padding: 1rem;
  text-align: center;
  font-size: 1.4rem;
  border-top: 1px solid #ED6B00;
}

.l-wrap {
  margin: auto;
  max-width: 1000px;
  width: 90%;
}

@media screen and (max-width: 767px) {
  .l-wrap {
    width: 90%;
  }
}

.l-logo {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  filter: drop-shadow(0 0 10px rgba(119, 119, 119, 0.15));
}

.l-logo_inner {
  width: 23rem;
  height: 23.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

@media screen and (max-width: 900px) {
  .l-logo_inner {
    width: 8rem;
    height: 8rem;
  }
}

@media screen and (max-width: 900px) {
  .l-logo_inner > img {
    width: 50%;
  }
}

.c-title, .c-title--far {
  margin: 0 auto 4rem;
}

@media screen and (max-width: 767px) {
  .c-title, .c-title--far {
    margin-bottom: 2rem;
  }
}

.c-title_sub {
  margin: 0 auto 2rem;
  text-align: center;
  font-weight: bold;
  color: #ED6B00;
}

@media screen and (max-width: 767px) {
  .c-title_sub {
    margin-bottom: 1rem;
  }
}

.c-title_main {
  margin: auto;
}

.c-title_main--left {
  margin: 0 auto 0 0;
}

.c-title--far {
  margin: 0 auto 6rem;
}

.c-section {
  padding: 8rem 0 12rem;
}

@media screen and (max-width: 767px) {
  .c-section {
    padding: 4rem 0 6rem;
  }
}

.c-section--s {
  padding: 0 0 8rem;
}

@media screen and (max-width: 767px) {
  .c-section--s {
    padding: 0 0 4rem;
  }
}

.c-background {
  background-color: #FFFAEC;
}

.c-comingSoon {
  border: 1px solid #C3C3C3;
  padding: .5em 0;
  color: #C3C3C3;
  font-weight: bold;
  font-size: 8rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-comingSoon {
    font-size: 3rem;
    padding: 1em 0;
  }
}

.c-row {
  display: flex;
  align-items: center;
  margin: 0 -2rem;
}

@media screen and (max-width: 767px) {
  .c-row {
    margin: 0;
    flex-direction: column;
  }
}

.c-row_item {
  flex-grow: 1;
  padding: 0 2rem;
}

@media screen and (max-width: 767px) {
  .c-row_item {
    padding: 0;
    margin: 0 0 2rem;
    width: 100%;
  }
}

.p-hero {
  position: relative;
  margin: 0 0 0 auto;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

@media screen and (max-width: 767px) {
  .p-hero {
    height: 600px;
  }
}

.p-hero_side {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 8%;
  padding-bottom: 20vh;
  text-align: right;
  writing-mode: vertical-rl;
  font-size: 2rem;
  font-family: sans-serif;
}

@media screen and (max-width: 767px) {
  .p-hero_side {
    padding-bottom: 0;
    font-size: 1.4rem;
  }
}

.p-hero_side::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45%;
  background-color: #FFFAEC;
  z-index: -1;
}

.p-hero_sideLine {
  position: relative;
  right: 0.2rem;
  margin-top: 2rem;
  display: block;
  width: 1px;
  height: 90px;
  background: linear-gradient(to bottom, black 50%, rgba(255, 255, 255, 0) 50%);
  background-position: 0 -100%;
  background-size: 100% 200%;
}

@media screen and (max-width: 767px) {
  .p-hero_sideLine {
    background-color: #000;
  }
}

@media screen and (min-width: 768px) {
  .p-hero_sideLine {
    animation: scrolldown 3s cubic-bezier(0.88, 0.04, 0.3, 1) forwards infinite;
  }
}

.p-hero_main {
  width: 92%;
  background-color: #FFFAEC;
}

.p-hero_bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%, 0% 80%);
}

@media screen and (max-width: 767px) {
  .p-hero_bg {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%, 0% 90%);
  }
}

.p-hero_ttl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.p-message_txt {
  font-size: 16px;
  margin: 0 auto 4.8rem;
  width: 80%;
  text-align: center;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .p-message_txt {
    width: 100%;
    margin-bottom: 2.4rem;
    text-align: left;
  }
}

.p-greetings_main {
  margin-bottom: 6rem;
}

@media screen and (max-width: 767px) {
  .p-greetings_main {
    margin-bottom: 3rem;
  }
}

.p-greetings_mainImg {
  flex-shrink: 0;
  width: 40%;
}

@media screen and (max-width: 767px) {
  .p-greetings_mainImg {
    width: 100%;
  }
}

.p-profile {
  padding: 5.4rem;
  border: 1px solid #ED6B00;
}

@media screen and (max-width: 767px) {
  .p-profile {
    padding: 2rem;
  }
}

.p-profile_img {
  flex-shrink: 0;
  width: 33.33333%;
}

@media screen and (min-width: 768px) {
  .p-profile_img {
    min-width: 30rem;
  }
}

@media screen and (max-width: 767px) {
  .p-profile_img {
    width: 100%;
  }
}

.p-profile_img > * {
  width: 100%;
}

.p-profile_title {
  width: 80%;
  margin-left: 0;
}

@media screen and (max-width: 767px) {
  .p-profile_txt {
    font-size: 1.4rem;
  }
}

.p-company {
  padding-bottom: 20rem;
}

@media screen and (max-width: 767px) {
  .p-company {
    padding-bottom: 10rem;
  }
}

.p-company_cont {
  width: fit-content;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .p-company_cont {
    width: 100%;
  }
}

.p-company_img {
  flex-shrink: 0;
  width: 50%;
}

@media screen and (max-width: 767px) {
  .p-company_img {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .p-company_img img {
    width: 100%;
  }
}

.p-company_txt {
  width: 50%;
  flex-shrink: 0;
}

@media screen and (max-width: 767px) {
  .p-company_txt {
    width: 100%;
  }
}

.p-contact_wrap {
  display: flex;
  flex-direction: column;
}

.p-contact_title {
  margin-top: -10rem;
}

@media screen and (max-width: 767px) {
  .p-contact_title {
    margin-top: -7rem;
  }
}

.p-contact_cont {
  width: 80%;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .p-contact_cont {
    width: 100%;
    margin: 2rem auto 0;
  }
}

.p-contact_item {
  width: 50%;
  flex-shrink: 0;
}

.p-contact_link {
  background-color: #ED6B00;
  color: #fff;
  font-weight: bold;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  line-height: 1;
}

.p-contact_link img {
  margin: 0 2rem 0 0;
}

.p-contact_link--tel {
  font-size: 2.4rem;
}

.u-clip-path-l {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%, 0% 80%);
}

.u-clip-path-r {
  clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0% 100%);
}

.u-clip-path-lr {
  clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 20% 100%, 0 80%);
}

.u-clip-path-s {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0% 40%);
}

@media screen and (max-width: 767px) {
  .u-clip-path-s {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0% 60%);
  }
}

.u-nowrap {
  white-space: nowrap;
}
