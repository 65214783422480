.c-comingSoon {
  border: 1px solid $gray;
  padding: .5em 0;
  color: $gray;
  font-weight: bold;
  font-size: 8rem;
  text-align: center;

  @include sp {
    font-size: 3rem;
    padding: 1em 0;
  }
}