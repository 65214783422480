.p-hero {
  position: relative;
  margin: 0 0 0 auto;
  height: 100vh;
  overflow: hidden;
  display: flex;

  @include sp {
    height: 600px;
  }

  &_side {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 8%;
    padding-bottom: 20vh;
    text-align: right;
    writing-mode: vertical-rl;
    font-size: 2rem;
    font-family: sans-serif;

    @include sp {
      padding-bottom: 0;
      font-size: 1.4rem;
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 45%;
      background-color: $bg;
      z-index: -1;
    }

    &Line {
      position: relative;
      right: 0.2rem;
      margin-top: 2rem;
      display: block;
      width: 1px;
      height: 90px;
      background: linear-gradient(to bottom, rgb(0, 0, 0) 50%, rgba(255, 255, 255, 0) 50%);
      background-position: 0 -100%;
      background-size: 100% 200%;

      @include sp {
        background-color: #000;
      }

      @include pc {
        animation: scrolldown 3s cubic-bezier(.88,.04,.3,1) forwards infinite;
      }
    }
  }

  &_main {
    width: 92%;
    background-color: $bg;
  }

  &_bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%, 0% 80%);

    @include sp {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%, 0% 90%);
    }
  }

  &_ttl {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}