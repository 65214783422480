.l-logo {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  filter: drop-shadow(0 0 10px rgba($color: #777, $alpha: 0.15));
  
  &_inner {
    width: 23rem;
    height: 23.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    @include tab-sp {
      width: 8rem;
      height: 8rem;
    }

    > img {
      @include tab-sp {
        width: 50%;
      }
    }
  }
}