.c-section {
  padding: 8rem 0 12rem;

  @include sp {
    padding: 4rem 0 6rem;
  }

  &--s {
    padding: 0 0 8rem;

    @include sp {
      padding: 0 0 4rem;
    }
  }
}