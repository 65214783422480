.p-contact {

  &_wrap {
    display: flex;
    flex-direction: column;
  }

  &_title {
    margin-top: -10rem;

    @include sp {
      margin-top: -7rem;
    }
  }

  &_cont {
    width: 80%;
    margin: auto;

    @include sp {
      width: 100%;
      margin: 2rem auto 0;
    }
  }

  &_item {
    width: 50%;
    flex-shrink: 0;
  }

  &_link {
    background-color: $main;
    color: #fff;
    font-weight: bold;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0;
    line-height: 1;

    img {
      margin: 0 2rem 0 0;
    }

    &--tel {
      font-size: 2.4rem;
    }
  }
}