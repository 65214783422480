.p-profile {
  padding: 5.4rem;
  border: 1px solid $main;

  @include sp {
    padding: 2rem;
  }

  &_img {
    flex-shrink: 0;
    width: 33.33333%;

    @include pc {
      min-width: 30rem;
    }

    @include sp {
      width: 100%;
    }

    > * {
      width: 100%;
    }
  }

  &_cont {

  }

  &_title {
    width: 80%;
    margin-left: 0;
  }

  &_txt {
    @include sp {
      font-size: 1.4rem;
    }
  }
}