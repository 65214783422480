.u-clip-path {
  &-l {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%, 0% 80%);
  }

  &-r {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0% 100%);
  }

  &-lr {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 20% 100%, 0 80%);
  }

  &-s {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0% 40%);

    @include sp {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%, 0% 60%);
    }
  }
}