*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  
  @include sp {
    // font-size: calc(100vw / 32);
  }
}

body {
  font-family: $noto;
  font-size: 1.6rem;
  line-height: 2;

  @include sp {
    line-height: 1.8;
  }

  &.is-overflow-hidden {
    overflow: hidden;
  }
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: $noto, 'メイリオ', Meiryo;
  }
}

main { // for IE 11
  display: block;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

img {
  max-width: 100%;
  display: block;
  margin: auto;
}

a {
  text-decoration: none;
  color: inherit;
  display: block;
  transition: opacity $anime;

  @include pc {
    &:hover {
      opacity: $opacity;
    }
  }
}

.pc {
  @include pc {
    display: block;
  }

  @include sp {
    display: none;
  }
}

.sp {
  @include pc {
    display: none;
  }

  @include sp {
    display: block;
  }
}