@charset "UTF-8";

// transition
$anime: 0.3s ease;
$opacity: 0.85;

//color
// main color
$main: #ED6B00;
$bg: #FFFAEC;

// black
$black: #000;

// white
$white: #fff;

// gray
$gray: #C3C3C3;

//フォント指定
// Noto
$noto: 'Noto Sans JP', sans-serif;
$notoSerif: 'Noto Serif JP', serif;

// 游ゴ・游明
$yumin: 游明朝, 'Yu Mincho', YuMincho, メイリオ, Meiryo, serif;
$yugo: '游ゴシック体', YuGothic, "游ゴシック", "Yu Gothic", 'メイリオ', sans-serif;

