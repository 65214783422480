@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab-pc {
  @media screen and (min-width: 901px) {
    @content;
  }
}

@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 900px) {
    @content;
  }
}

@mixin tab-sp {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@keyframes scrolldown {
	0%{
		background-position: 0 100%;
	}
	40% {
		background-position: 0 0%;
  }
  
  75% {
		background-position: 0 0%;
  }
  
	90%{
		background-position: 0 -100%;
	}
}