.l-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 2rem 0 0;
  color: #fff;
  z-index: 99;

  @include tab-sp {
    padding: 0;
  }

  &_inner {
    &--pc {
      @include tab-sp {
        display: none;
      }
    }

    &--mobile {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: opacity .3s .1s;

      @include tab-pc {
        display: none;
      }

      &.is-active {
        overflow: auto;
        opacity: 1;
        height: 100vh;
      }
    }
  }

  &_menu {
    margin: 0 20% 0 auto;
    display: flex;
    justify-content: flex-end;

    @include tab-sp {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0;
      background-color: $main;
      height: 100%;
    }
  }
  
  &_item {
    text-align: center;
    width: 25%;
    max-width: 12.5rem;
    border-right: 1px solid #fff;

    @include tab-sp {
      width: 100%;
      max-width: none;
      border-right: none;
      transform: translateY(-40%);
    }

    &:last-child {
      border: none;
    }
  }

  &_link {
    @include tab-sp {
      width: 50%;
      line-height: 2;
      margin: 2rem auto;
    }
  }

  &_contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 8%;
    top: 0;
    background-color: $main;
    width: 126px;
    height: 65px;
    text-align: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 75%, 85% 100%, 15% 100%, 0 75%);

    &Arrow {
      margin-top: 0.5rem;
    }
  }

  &_menuTrigger {
    background-color: $main;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 8rem;
    height: 8rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    z-index: 99;
    border: none;
    outline: none;

    @include tab-sp {
      display: flex;
    }

    &_line {
      width: 3rem;
      height: 1px;
      background-color: #fff;
      transform-origin: right center;
      transition: all .3s ease;

      &01 {
        @extend .l-header_menuTrigger_line;
      }

      &02 {
        @extend .l-header_menuTrigger_line;
        margin: .8rem 0;
      }

      &03 {
        @extend .l-header_menuTrigger_line;

      }
    }

    &.is-active {
      .l-header_menuTrigger_line {
        &01 {
          transform: rotate(-45deg) translateY(-.3rem);
        }

        &02 {
          width: 0;
          opacity: 0;
        }

        &03 {
          transform: rotate(45deg) translateY(.3rem);
        }
      }
    }
  }
}