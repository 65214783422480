.p-company {
  padding-bottom: 20rem;

  @include sp {
    padding-bottom: 10rem;
  }

  &_cont {
    width: fit-content;
    margin: auto;

    @include sp {
      width: 100%;
    }
  }

  &_img {
    flex-shrink: 0;
    width: 50%;

    @include sp {
      width: 100%;
    }

    img {
      @include sp {
        width: 100%;
      }
    }
  }

  &_txt {
    width: 50%;
    flex-shrink: 0;

    @include sp {
      width: 100%;
    }
  }
}